<template>
  <v-dialog v-model="newDialog" persistent max-width="700">
    <v-card>
      <v-card-title>New Subscriber</v-card-title>

      <v-card outlined color="#f6f6ff" style="margin: 0 0 16px 0">
        <v-card-title>Personal Info</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-select
                v-model="register.salutationid"
                :items="saluteList"
                dense
                item-text="name"
                item-value="id"
                label="salutation"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="register.first_name"
                dense
                label="first name"
                name="name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="register.last_name"
                dense
                label="last name"
                name="name"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="4">
              <v-select
                v-model="register.gendersid"
                :items="gendersList"
                dense
                item-text="name"
                item-value="id"
                label="Gender"
                loader-height="1"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="register.email"
                dense
                label="email"
                name="name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="register.mobile_phone"
                dense
                label="mobile phone"
                name="name"
                maxLength="10"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="4">
              <v-select
                v-model="register.countryid"
                :items="countrys"
                dense
                item-text="name"
                item-value="id"
                label="country"
                name="name"
                outlined
                return-object
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="register.regionsid"
                :items="regions"
                dense
                item-text="name"
                item-value="id"
                label="regions"
                name="name"
                outlined
                return-object
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="register.districtsid"
                :items="districts"
                dense
                item-text="name"
                item-value="id"
                label="district"
                outlined
              ></v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="register.city"
                dense
                label="city"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="register.physical_address"
                dense
                label="physical address"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="register.username"
                :append-icon="usercheck"
                :hint="userNameHint"
                :loading="usernameLoad"
                :minlength="userNameMin"
                counter
                dense
                label="Username"
                loader-height="2"
                name="name"
                outlined
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card outlined color="#f6f6ff">
        <v-card-title>Business Info</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-select
                v-model="register.specializationId"
                :items="specialtyList"
                dense
                item-text="name"
                item-value="id"
                label="cadres"
                outlined
                return-object
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="register.specialization_detailsid"
                :items="specialtyDetailList"
                dense
                item-text="name"
                item-value="id"
                label="specialization"
                outlined
              ></v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6">
              <v-select
                v-model="register.professional_licensesid"
                :items="professionList"
                dense
                item-text="name"
                item-value="id"
                label="professional body"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="register.registration_number"
                dense
                label="registration number"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="register.business_name"
                dense
                label="business name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="register.business_email"
                dense
                label="business email"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card-text> </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.register.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import Restful from "@/services/RestFul";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: {
    newDialog: Boolean,
    subType: { type: Number, default: 0 },
    myRole: { type: Number, default: 0 },
  },
  data() {
    return {
      regAble: true,
      distAble: true,
      cityAble: true,
      spesAble: true,
      loading: false,
      responseMessage: null,
      saluteList: [],
      countrys: [],
      regions: [],
      districts: [],
      gendersList: [],
      specialtyList: [],
      specialtyDetailList: [],
      usercheck: "mdi-tooltip-question-outline",
      userNameHint: null,
      usernameLoad: false,
      userNameMin: 5,
      professionList: [],
      register: {
        salutationid: null,
        first_name: null,
        last_name: null,

        gendersid: null,
        email: null,
        mobile_phone: null,

        countryid: null,
        regionsid: null,
        districtsid: null,

        city: null,
        physical_address: null,
        username: null,

        specializationId: 1,
        specialization_detailsid: 1,

        professional_licensesid: null,
        registration_number: null,

        business_name: null,
        business_email: null,

        subscriber_types_id: null,
        dependency: false,
        mytos: true,
      },
    };
  },
  validations: {
    register: {
      salutationid: { required },
      first_name: { required },
      last_name: { required },
      gendersid: { required },
      email: { required, email },
      mobile_phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      countryid: { required },
      regionsid: { required },
      districtsid: { required },
      city: { required },
      physical_address: { required },
      username: {
        required,
        minLength: minLength(5),
      },
      specializationId: { required },
      specialization_detailsid: { required },
      professional_licensesid: { required },
      registration_number: { required },
      // subscriber_types_id: { required },
      // business_email: { email },
    },
  },
  watch: {
    "register.countryid"(id) {
      console.log(id.id);
      this.regionlist(id.id);
      this.profession(id.id);
      this.regAble = false;
    },
    "register.regionsid"(id) {
      console.log(id.id);
      this.districtlist(id.id);
      this.distAble = false;
    },
    "register.districtsId"(id) {
      if (id > 0) {
        console.log(id.id);
        this.cityAble = false;
      }
    },
    "register.username": function (a) {
      console.log(a);
      this.checkUsername(a);
      if (a === null) {
        this.userNameHint = "";
      } else {
        if (a.length < 5) {
          this.userNameHint = "Should be more than 5 characters";
        } else {
          this.userNameHint = "";
        }
      }
    },
    "register.specializationId"(id) {
      console.log(id.id);
      this.specialtyDetail(id.id);
      this.spesAble = false;
    },
  },
  created() {
    this.genders();
    this.salutation();
    this.country();
    this.specialty();
  },
  methods: {
    specialty() {
      Restful.general.specialties
        .list()
        .then((response) => {
          console.log(response);
          this.specialtyList = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    specialtyDetail(id) {
      Restful.general.specialDetails
        .list(id)
        .then((response) => {
          console.log(response);
          this.specialtyDetailList = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    genders() {
      Restful.general.genders
        .list()
        .then((response) => {
          console.log(response);
          this.gendersList = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    salutation() {
      Restful.general.salutations
        .list()
        .then((response) => {
          console.log(response);
          this.saluteList = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    country() {
      Restful.general.country
        .list()
        .then((response) => {
          console.log(response);
          this.countrys = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    regionlist(country) {
      Restful.general.regions
        .byCountry(country)
        .then((response) => {
          console.log(response);
          this.regions = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    districtlist(reg) {
      Restful.general.districts
        .findbyRegions(reg)
        .then((response) => {
          console.log(response);
          this.districts = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    profession(a) {
      this.cload = true;
      let self = this;
      Restful.general.profession
        .country(a)
        .then((response) => {
          console.log(response);
          this.professionList = response.data;
          this.cload = false;
        })
        .catch((error) => {
          console.log(error);
          self.cload = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeNew", payload);
    },
    checkUsername(data) {
      if (data === null) {
        this.usernameLoad = false;
        this.usercheck = "mdi-tooltip-question-outline";
      } else {
        if (data.length > 0) {
          this.usernameLoad = true;
          let self = this;
          Restful.entry
            .checkUsername(data)
            .then((response) => {
              console.log(response);
              this.checkUser = response.data;
              if (response.data === true) {
                this.usercheck = "mdi-check";
                this.userNameHint = "Good to go!";
              } else {
                this.userNameHint = "Username is already registered";
                this.usercheck = "mdi-close";
              }
              this.usernameLoad = false;
            })
            .catch((error) => {
              console.log(error);
              self.usernameLoad = false;
            });
        } else {
          this.usernameLoad = false;
          this.usercheck = "mdi-tooltip-question-outline";
        }
      }
    },
    submit() {
      // console.log(this.view);
      let salutationid = this.register.salutationid;
      let first_name = this.register.first_name;
      let last_name = this.register.last_name;

      let gendersid = this.register.gendersid;
      let email = this.register.email;
      let mobile_phone = this.register.mobile_phone;

      let countryid = this.register.countryid.id;
      let regionsid = this.register.regionsid.id;
      let districtsid = this.register.districtsid;

      let city = this.register.city;
      let physical_address = this.register.physical_address;
      let username = this.register.username;

      let specializationId = this.register.specializationId.id;
      let specialization_detailsid = this.register.specialization_detailsid;

      let professional_licensesid = this.register.professional_licensesid;
      let registration_number = this.register.registration_number;

      let business_name = this.register.business_name;
      let business_email = this.register.business_email;

      let subscriber_types_id = parseInt(this.subType);
      let dependency = false;

      let data = {
        dependency,
        subscriber_types_id,
        specializationId,
        specialization_detailsid,
        salutationid,
        first_name,
        last_name,
        gendersid,
        email,
        mobile_phone,
        physical_address,
        business_name,
        countryid,
        regionsid,
        districtsid,
        city,
        username,
        professional_licensesid,
        registration_number,
        business_email,
      };
      let payload = { state: false, data: data };
      console.log("payload:", payload);
      this.$emit("submitNew", payload);
    },
  },
};
</script>
